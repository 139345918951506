import React, { useState } from "react";
import user_avatar from "assets/images/user_avatar.png";

interface IProps {
  ownerAvatar: string;
  onSend: (data: string) => void;
}

export const CommentInput = ({ onSend, ownerAvatar }: IProps) => {
  const [comment, setComment] = useState<string>("");

  const onPressSendHandler = () => {
    onSend(comment);
    setComment("");
  };

  return (
    <div className={"flex px-[20px] items-center gap-[10px]"}>
      <img
        alt={""}
        src={ownerAvatar ?? user_avatar}
        className={"rounded-[10px] w-[30px] h-[30px]"}
      />
      <div
        className={
          "flex w-full border-[0.5px] py-[5px] px-[10px] rounded-[10px]"
        }
      >
        <input
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder={"Оставьте комментарий..."}
          className={`input border-0 w-full text-12 rounded-none peer placeholder-white focus:outline-none focus:border-blue`}
        />
        <button
          className={`color-[#475CC9] font-medium text-10 disabled:opacity-75`}
          disabled={!comment}
          onClick={onPressSendHandler}
        >
          Отправить
        </button>
      </div>
    </div>
  );
};
