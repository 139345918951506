import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { followTC, getFollowersTC, unfollowTC } from "store/thunks";
import { getDataForSubscriptionsPage } from "store/selectors";
import {
  ButtonSubscribeOrUnsubscribe,
  EmptyPage,
  Header,
  Typography,
} from "shared/ui";
import { PAGE_TYPE } from "store/constants";
import user_avatar from "assets/images/user_avatar.png";

export const SubscriptionsList = () => {
  const dispatch = useDispatch();
  const { nickname } = useParams();
  const navigation = useNavigate();
  const { followers, page, size, loader } = useSelector(
    getDataForSubscriptionsPage
  );

  useEffect(() => {
    if (nickname) {
      dispatch(getFollowersTC(nickname, page, size));
    }
  }, [nickname]);

  const onPressBack = () => {
    navigation(-1);
  };

  const follow = (userName: string) => {
    if (nickname) {
      dispatch(
        followTC(userName, PAGE_TYPE.SUBSCRIPTIONS, nickname, page, size)
      );
    }
  };
  const unfollow = (userName: string) => {
    if (nickname) {
      dispatch(
        unfollowTC(userName, PAGE_TYPE.SUBSCRIPTIONS, nickname, page, size)
      );
    }
  };

  return (
    <div className={"flex flex-col h-full"}>
      <Header title="Подписки" onLeft={onPressBack} />
      {!loader && followers.length === 0 ? (
        <EmptyPage text={"У вас пока подписок"} />
      ) : (
        <div>
          {followers.map((user) => {
            return (
              <div
                key={user.nickname}
                className="flex justify-between px-20 py-10"
              >
                <Link to={`/${user.nickname}`}>
                  <div className="flex items-center">
                    <img
                      className="w-[40px] h-[40px] mr-10 rounded-2xl"
                      src={user?.avatar ?? user_avatar}
                      alt=""
                    />
                    <Typography text={user.nickname} variant="h4" />
                  </div>
                </Link>
                <div className="flex items-center w-[140px]">
                  <ButtonSubscribeOrUnsubscribe
                    onClick={user.follower ? unfollow : follow}
                    label={user.follower ? "Отписаться" : "Подписаться"}
                    appearance={user.follower ? "unsubscribe" : "subscribe"}
                    nickname={user.nickname}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
