import { RootStateType } from "store/store";

export const getIsAuth = (state: RootStateType) => state.auth.isAuth;

export const getDataForSignInPage = (state: RootStateType) => ({
  isAuth: state.auth.isAuth,
  loader: state.app.loader,
});

export const getDataForSignUp = (state: RootStateType) => state.auth.timeZones;

export const getMyUserData = (state: RootStateType) => ({
  isAuth: state.auth.isAuth,
  user: state.auth.user,
});
