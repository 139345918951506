import React from "react";
import { useDispatch } from "react-redux";
import { IChangePasswordForm } from "interfaces";
import { changePasswordTC } from "store/thunks";
import { ChangePassword } from "./changePassword";
import { useNavigate } from "react-router-dom";

export const ChangePasswordPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const onSubmitHandler = async (data: IChangePasswordForm) => {
    await dispatch(changePasswordTC(data, goBack));
  };
  return <ChangePassword onSubmitHandler={onSubmitHandler} />;
};
