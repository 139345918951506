import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { confirmEmailTC } from "store/thunks";

export const ConfirmEmail = () => {
  const dispatch = useDispatch();

  const [query] = useSearchParams();
  const token = query.get("token");

  useEffect(() => {
    if (token) {
      dispatch(confirmEmailTC(token));
    }
  }, [token, dispatch]);
  return <></>;
};
