import { SET_FEEDS } from "store/constants";
import { feedActions } from "store/actions";
import { InferActionsType } from "store/store";
import { IFeed } from "interfaces";

export type IActions = InferActionsType<typeof feedActions>;
export type IFeedsReducer = typeof defaultState;

const defaultState = {
  feeds: [] as IFeed[],
};

export const feedsReducer = (
  state = defaultState,
  action: IActions
): IFeedsReducer => {
  switch (action.type) {
    case SET_FEEDS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
