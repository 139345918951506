import axios from "axios";

const host = "https://api-www.zdymak.live";

export const instance = axios.create({
  baseURL: host + "/main/api",
  withCredentials: true,
});
export const instanceApi = axios.create({
  baseURL: host,
  withCredentials: true,
});
