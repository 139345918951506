import React from "react";
import { SignIn } from "./signIn";
import { useDispatch, useSelector } from "react-redux";
import { ISignInForm } from "interfaces";
import { loginTC } from "store/thunks";
import { getDataForSignInPage } from "store/selectors";
import { Navigate } from "react-router-dom";
import { ROUTES } from "app/constants";

export const SignInPage = () => {
  const dispatch = useDispatch();
  const { isAuth, loader } = useSelector(getDataForSignInPage);

  const onSubmitHandler = async (data: ISignInForm) => {
    await dispatch(loginTC(data));
  };
  if (isAuth) {
    return <Navigate to={ROUTES.MAIN} replace />;
  }

  return <SignIn onSubmitHandler={onSubmitHandler} loading={loader} />;
};
