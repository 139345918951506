import React from "react";
import { SignUp } from "./signUp";
import { useDispatch, useSelector } from "react-redux";
import { getIsAuth } from "store/selectors";
import { Navigate } from "react-router-dom";
import { ROUTES } from "app/constants";
import { ISignUpForm } from "interfaces";
import { registerTC } from "store/thunks";

export const SignUpPage = () => {
  const dispatch = useDispatch();
  const isAuth = useSelector(getIsAuth);
  if (isAuth) {
    return <Navigate to={ROUTES.MAIN} replace />;
  }

  const onSubmitHandler = async (data: ISignUpForm) => {
    await dispatch(registerTC(data));
  };

  return <SignUp onSubmitHandler={onSubmitHandler} />;
};
