import React from "react";
import { Typography } from "../typography";
import { ReactComponent as RightArrow } from "assets/icons/rightArrow/rightArrow.svg";
import { useNavigate } from "react-router-dom";

interface IProps {
  title: string;
  onLeft?: () => void;
  onRight?: () => void;
  rightTitle?: string;
  formId?: string;
}

export const Header = ({
  title,
  onLeft,
  onRight,
  rightTitle,
  formId,
}: IProps) => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <div className={"grid grid-cols-3 items-center px-[20px] py-[12px]"}>
      <span onClick={onLeft ? onLeft : goBack} className="mr-[10px]">
        <RightArrow />
      </span>
      <Typography text={title} variant={"h2"} align={"center"} />
      {rightTitle ? (
        <button
          form={formId}
          className={"text-12 text-right font-medium"}
          onClick={onRight}
        >
          {rightTitle}
        </button>
      ) : (
        <div />
      )}
    </div>
  );
};
