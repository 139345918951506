import React, { useEffect, useState } from "react";
import { Button } from "shared/form";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "app/constants";
import { ReactComponent as NotFoundImg_0 } from "assets/images/404_0.svg";
import { ReactComponent as NotFoundImg_1 } from "assets/images/404_1.svg";
import { ReactComponent as NotFoundImg_2 } from "assets/images/404_2.svg";
import { ReactComponent as NotFoundImg_3 } from "assets/images/404_3.svg";
import { randomNumber } from "shared/utils/randomNumber";

export const NotFound = () => {
  const navigate = useNavigate();
  const onPressHomeHandler = () => {
    navigate(ROUTES.MAIN);
  };

  const pictureArray: any = {
    img_0: <NotFoundImg_0 />,
    img_1: <NotFoundImg_1 />,
    img_2: <NotFoundImg_2 />,
    img_3: <NotFoundImg_3 />,
  };
  const [img, setImg] = useState(pictureArray["img_0"]);

  useEffect(() => {
    setImg(`img_${randomNumber(0, 4)}`);
  }, []);

  return (
    <div
      className={
        "h-screen w-screen px-17 flex flex-col justify-center items-center"
      }
    >
      <div
        className={
          "h-full w-full flex items-center justify-center flex-col max-w-[400px]"
        }
      >
        {pictureArray[img]}
        <Button
          className={"bg-main_blue_text w-full mt-[60px]"}
          label={"На главную"}
          onClick={onPressHomeHandler}
        />
      </div>
    </div>
  );
};
