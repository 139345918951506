import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "app/constants";
import { Header } from "shared/ui";
import { CancelNewPostModal } from "features/newPost";

export const PrepareNewPost = () => {
  const location = useLocation();
  const navigation = useNavigate();
  const [showModal, setShowModal] = useState<boolean>(false);
  useEffect(() => {
    return () => {
      location.state = {};
    };
  }, []);
  const continueHandler = () => {
    navigation(ROUTES.NEW_POST, { state: location.state });
  };
  const onPressBack = () => {
    setShowModal(true);
  };
  const onPressDeletePost = () => {
    navigation(-1);
    location.state = {};
  };
  return (
    <div className="w-full flex flex-col">
      <CancelNewPostModal
        open={showModal}
        onCancel={() => setShowModal(false)}
        onDelete={onPressDeletePost}
      />

      <Header
        title={"Публикация"}
        onLeft={onPressBack}
        rightTitle={"Далее"}
        onRight={continueHandler}
      />
      <div className={"flex justify-center"}>
        <img
          className={"w-[40vh]"}
          src={URL.createObjectURL(location?.state as File)}
          alt="Thumb"
        />
      </div>
    </div>
  );
};
