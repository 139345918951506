import { appActions, feedActions, postActions } from "store/actions";
import { BaseThunkType, InferActionsType } from "store/store";
import { commonThunkCreator } from "shared/utils";
import { feedsAPI } from "api";

type ThunkType = BaseThunkType<
  InferActionsType<typeof feedActions | typeof appActions | typeof postActions>
>;

export const getFeeds =
  (page: number = 0): ThunkType =>
  async (dispatch, getState) => {
    await commonThunkCreator(async () => {
      try {
        const res = await feedsAPI.getFeeds(page);
        const feeds = getState().feeds.feeds;
        dispatch(feedActions.setFeedsAC([...feeds, ...res.data.body?.content]));
        dispatch(postActions.setTotalCountAC(res.data?.body?.totalElements));
      } finally {
        dispatch(appActions.setInitializeAC(true));
      }
    }, dispatch);
  };
