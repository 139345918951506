import React from "react";
import { ReactComponent as MainIcon } from "assets/icons/bottomNavigation/main.svg";
import { ReactComponent as AddPostIcon } from "assets/icons/bottomNavigation/addPost.svg";
import { ReactComponent as Profile } from "assets/icons/bottomNavigation/profile.svg";
import { Link } from "react-router-dom";
import { ROUTES } from "app/constants/routes";
import { useDispatch, useSelector } from "react-redux";
import { getMyUserData } from "store/selectors";
import { postActions } from "store/actions";

export const BottomNavigation = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(getMyUserData);

  const newPostModalHandler = () => {
    dispatch(postActions.setOpenNewPostModalAC(true));
  };

  return (
    <div className={"fixed w-full max-w-[800px] bottom-[0.1px] left-0"}>
      <div
        className={
          "flex w-full bg-white justify-between items-center h-[80px] px-[36px] py-[20px]"
        }
      >
        <Link to={ROUTES.MAIN}>
          <MainIcon />
        </Link>
        <span className={"cursor-pointer"} onClick={newPostModalHandler}>
          <AddPostIcon />
        </span>
        <Link to={`/${user?.nickname}`}>
          <Profile />
        </Link>
      </div>
    </div>
  );
};
