import React, { useState } from "react";
import { IPost } from "interfaces";
import { ReactComponent as LikeIcon } from "assets/icons/like.svg";
import { ReactComponent as DisLikeIcon } from "assets/icons/dislike.svg";
import { ReactComponent as EditePostIcon } from "assets/icons/edit.svg";
import { Typography } from "shared/ui";
import { ReactComponent as RightArrow } from "assets/icons/rightArrow/rightArrow.svg";
import { Link, useNavigate } from "react-router-dom";
import { COMPLAIN_TYPE, ROUTES } from "app/constants";
import { ComplainModal } from "../complainModal/complainModal";
import { useDispatch } from "react-redux";
import { dislikeTC, likeTC, sendComplain } from "store/thunks";
import { SuccessModal } from "../successModal/successModal";
import user_avatar from "assets/images/user_avatar.png";

interface IProps {
  item: IPost;
  isMyPost: boolean;
}

export const PostCard = ({ item, isMyPost }: IProps) => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);

  const goToProfileHandler = () => {
    navigation(`/${item.author}`);
  };

  const sendComplainHandler = (complain: string) => {
    dispatch(
      sendComplain(
        item.author,
        COMPLAIN_TYPE.POST,
        complain,
        successSendComplainHandler
      )
    );
  };

  const successSendComplainHandler = () => {
    setShowModal(false);
    setShowSuccessModal(true);
  };

  const onPressLikeHandler = (id: string) => {
    dispatch(likeTC(id));
  };
  const onPressDisLikeHandler = (id: string) => {
    dispatch(dislikeTC(id));
  };

  return (
    <div className={"flex flex-col w-full"}>
      <ComplainModal
        onPressSend={sendComplainHandler}
        open={showModal}
        onClose={() => setShowModal(false)}
      />
      <SuccessModal
        open={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
      />
      <div
        className={
          "flex items-start justify-between  px-[20px] pt-[12px] mb-[20px]"
        }
      >
        <RightArrow onClick={() => navigation(-1)} className="mt-[20px]" />
        <div className={"flex flex-col items-center justify-center w-full"}>
          <img
            onClick={goToProfileHandler}
            src={item?.avatarOwner ?? user_avatar}
            className={
              "rounded-[10px] object-cover cursor-pointer h-full mb-[8px] h-[40px] w-[40px]"
            }
          />
          <div
            onClick={goToProfileHandler}
            className={"flex cursor-pointer justify-center"}
          >
            {item.author}
          </div>
        </div>
      </div>
      {isMyPost && (
        <div className="flex justify-end pr-[12px] pb-[4px]">
          <Link
            to={`${ROUTES.EDITE_POST}/${item.id}`}
            state={{ filePath: item.filePath, description: item.description }}
          >
            <EditePostIcon />
          </Link>
        </div>
      )}
      <div className={"flex flex-col items-center justify-center w-full"}>
        <div className={"flex w-full bg-cover items-center justify-center "}>
          <img className={"max-h-[700px]"} src={item.filePath} />
        </div>
        <div className={"flex flex-col w-full px-[20px] py-[8px]"}>
          <div className={"flex justify-between items-center"}>
            <div className={"flex"}>
              <div className={"flex mr-[8px]"}>
                <LikeIcon
                  className={"cursor-pointer"}
                  onClick={() => onPressLikeHandler(item?.id)}
                />
                <Typography text={item.likesCount} variant={"h3"} />
              </div>
              <div className={"flex mr-[8px]"}>
                <DisLikeIcon
                  className={"mt-[4px] cursor-pointer"}
                  onClick={() => onPressDisLikeHandler(item?.id)}
                />
                <Typography text={item.dislikesCount} variant={"h3"} />
              </div>
            </div>
            {!isMyPost && (
              <div
                onClick={() => setShowModal(true)}
                className={"text-10 cursor-pointer text-[#FF4848] font-normal"}
              >
                Пожаловаться
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
