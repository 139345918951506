import React from "react";
import { SignInForm } from "features/auth";
import { Logo } from "shared/ui";
import { ISignInForm } from "interfaces";

interface IProps {
  onSubmitHandler: (data: ISignInForm) => void;
  loading: boolean;
}

export const SignIn = ({ onSubmitHandler, loading }: IProps) => {
  return (
    <div className={"h-screen w-screen flex justify-center items-center"}>
      <div
        className={
          "flex flex-col max-w-[800px] justify-center items-center w-full px-[21px]"
        }
      >
        <Logo />
        <SignInForm loader={loading} onSubmitHandler={onSubmitHandler} />
      </div>
    </div>
  );
};
