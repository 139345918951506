import { appActions, authActions } from "store/actions";
import { BaseThunkType, InferActionsType } from "store/store";
import { commonThunkCreator } from "shared/utils";
import { IChangePasswordForm, ISettingsForm } from "interfaces";
import { settingsAPI } from "api/settings";
import { fileAPI } from "../../api";
import { authMeTC } from "./auth";

type ThunkType = BaseThunkType<
  InferActionsType<typeof authActions | typeof appActions>
>;

export const changeSettingsTC =
  (data: ISettingsForm, callback: () => void): ThunkType =>
  async (dispatch) => {
    await commonThunkCreator(async () => {
      dispatch(appActions.setLoaderAC(true));
      await settingsAPI.changeSettings(data);
      await dispatch(authMeTC());
      callback();
    }, dispatch);
    dispatch(appActions.setLoaderAC(false));
  };
export const changePasswordTC =
  (data: IChangePasswordForm, callback: () => void): ThunkType =>
  async (dispatch) => {
    await commonThunkCreator(async () => {
      dispatch(appActions.setLoaderAC(true));
      await settingsAPI.changePassword(data);
      callback();
    }, dispatch);
    dispatch(appActions.setLoaderAC(false));
  };

export const changeAvatarTC =
  (photo: File): ThunkType =>
  async (dispatch) => {
    await commonThunkCreator(async () => {
      dispatch(appActions.setLoaderAC(true));
      const res = await fileAPI.uploadFile(photo);
      await settingsAPI.changeAvatar(res.data.id);
    }, dispatch);
    dispatch(appActions.setLoaderAC(false));
  };
