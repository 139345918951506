import React, { ChangeEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Header, Loader } from "shared/ui";
import { useDispatch } from "react-redux";
import { createPost } from "store/thunks";
import { ROUTES } from "app/constants";
import { CancelNewPostModal } from "features/newPost";

export const NewPost = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [image] = useState<File | unknown>(location?.state);
  const [description, setDescription] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  useEffect(() => {
    return () => {
      location.state = {};
    };
  }, []);

  const onPressBack = () => {
    setShowModal(true);
  };
  const onPressDeletePost = () => {
    navigation(-2);
    location.state = {};
  };

  const redirectAfterCreatePost = () => {
    navigation(ROUTES.MAIN, { replace: true });
  };

  const onChangeDescriptionHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.currentTarget.value);
  };

  const onPressAddPost = async () => {
    setLoading(true);
    await dispatch(
      createPost(image as File, description, redirectAfterCreatePost)
    );
    setLoading(false);
  };

  return (
    <div className={"flex flex-col  "}>
      <CancelNewPostModal
        open={showModal}
        onCancel={() => setShowModal(false)}
        onDelete={onPressDeletePost}
      />
      <Header
        title={"Публикация"}
        onLeft={onPressBack}
        rightTitle={"Поделиться"}
        onRight={onPressAddPost}
      />
      <div className={"flex flex-col"}>
        <div className={"flex justify-center"}>
          <img
            className={"w-[40vh]"}
            src={URL.createObjectURL(image as Blob)}
            alt="Thumb"
          />
        </div>
        <div className={"p-[20px]"}>
          <textarea
            placeholder={"Добавьте описание..."}
            className={
              "border-[#45B4FA] min-h-[170px] px-[10px] py-[8px] text-12 border-[1px] w-full resize-none"
            }
            value={description}
            onChange={onChangeDescriptionHandler}
          />
        </div>
      </div>
    </div>
  );
};
