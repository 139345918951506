import React from "react";

interface IProps {
  open: boolean;
  onCancel: () => void;
  onDelete: () => void;
}

export const CancelNewPostModal = ({ open, onCancel, onDelete }: IProps) => {
  if (!open) {
    return null;
  }
  return (
    <div
      onClick={onCancel}
      className={"fixed top-0 left-0 z-50 w-full h-screen bg-modal_bg"}
    >
      <div
        className={"flex items-center justify-center w-full h-screen px-[5%]"}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className={"min-w-[150px] rounded-[20px] bg-white"}
        >
          <div className={"flex flex-col pt-[15px] items-center"}>
            <div
              className={"text-black text-center text-16 font-medium pb-[20px]"}
            >
              Удалить публикацию?
            </div>
            <div
              className={
                "px-[15px] text-black text-center opacity-50 text-12 font-normal pb-[25px]"
              }
            >
              Если вы выйдете, ваша публикация не будет сохранена.
            </div>

            <div
              onClick={onDelete}
              className={
                "cursor-pointer text-center text-12 font-medium text-[#FF4848] py-[12px] border-[#B3B3B3] border-t-[0.5px] w-full"
              }
            >
              Удалить
            </div>

            <div
              onClick={onCancel}
              className={
                "cursor-pointer text-center text-12 font-medium color-[#FF4848] py-[12px] border-[#B3B3B3] border-t-[0.5px] w-full"
              }
            >
              Отмена
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
