import { commonThunkCreator } from "shared/utils";
import { profileAPI } from "api";
import { appActions, profileActions } from "store/actions";
import { BaseThunkType, InferActionsType } from "store/store";
import { PAGE_TYPE } from "store/constants";

type ThunkType = BaseThunkType<
  InferActionsType<typeof profileActions | typeof appActions>
>;

export const getProfileDataTC =
  (nickname: string): ThunkType =>
  async (dispatch) => {
    await commonThunkCreator(async () => {
      dispatch(appActions.setLoaderAC(true));
      const userData = await profileAPI.getUserData(nickname);
      dispatch(profileActions.setUserData(userData.data.body));
      dispatch(appActions.setLoaderAC(false));
    }, dispatch);
  };

export const getUserPostsTC =
  (nickname: string, page: number, size: string): ThunkType =>
  async (dispatch) => {
    await commonThunkCreator(async () => {
      const postsData = await profileAPI.getUserPosts(nickname, page, size);
      dispatch(profileActions.setPostsData(postsData.data.body.content));
    }, dispatch);
  };

export const getFollowersTC =
  (
    nickname: string | undefined,
    page: number | undefined,
    size: string | undefined
  ): ThunkType =>
  async (dispatch) => {
    await commonThunkCreator(async () => {
      dispatch(appActions.setLoaderAC(true));
      const followers = await profileAPI.getFollowers(nickname, page, size);
      dispatch(profileActions.setFollowers(followers.data.content));
      dispatch(appActions.setLoaderAC(false));
    }, dispatch);
  };

export const getSubscriptionsTC =
  (
    nickname: string | undefined,
    page: number | undefined,
    size: string | undefined
  ): ThunkType =>
  async (dispatch) => {
    await commonThunkCreator(async () => {
      dispatch(appActions.setLoaderAC(true));
      const subscriptions = await profileAPI.getSubscriptions(
        nickname,
        page,
        size
      );
      dispatch(profileActions.setSubscriptions(subscriptions.data.content));
      dispatch(appActions.setLoaderAC(false));
    }, dispatch);
  };

export const followTC =
  (
    userName: string,
    whatPage: string,
    nickname?: string | undefined,
    page?: number | undefined,
    size?: string | undefined
  ): ThunkType =>
  async (dispatch) => {
    await commonThunkCreator(async () => {
      const isFollow = await profileAPI.follow(userName);
      if (isFollow.data.status) {
        if (whatPage === PAGE_TYPE.FOLLOWERS) {
          await dispatch(getSubscriptionsTC(nickname, page, size));
        }
        if (whatPage === PAGE_TYPE.SUBSCRIPTIONS) {
          await dispatch(getFollowersTC(nickname, page, size));
        }
        if (whatPage === PAGE_TYPE.PROFILE) {
          await dispatch(getProfileDataTC(userName));
        }
      }
    }, dispatch);
  };

export const unfollowTC =
  (
    userName: string,
    whatPage: string,
    nickname?: string | undefined,
    page?: number,
    size?: string
  ): ThunkType =>
  async (dispatch) => {
    await commonThunkCreator(async () => {
      const isUnfollow = await profileAPI.unfollow(userName);
      if (isUnfollow.data.status) {
        if (whatPage === PAGE_TYPE.FOLLOWERS) {
          await dispatch(getSubscriptionsTC(nickname, page, size));
        }
        if (whatPage === PAGE_TYPE.SUBSCRIPTIONS) {
          await dispatch(getFollowersTC(nickname, page, size));
        }
        if (whatPage === PAGE_TYPE.PROFILE) {
          await dispatch(getProfileDataTC(userName));
        }
      }
    }, dispatch);
  };
