import React from "react";
import { Typography } from "shared/ui";
import { Link } from "react-router-dom";
import { IUserData } from "interfaces";
import avatar_logo from "assets/images/user_avatar.png";
import { ROUTES } from "app/constants";

interface IProps {
  userData: IUserData;
}

export const MyProfileInfo = ({ userData }: IProps) => {
  return (
    <div className="flex flex-col w-full p-[20px]">
      <div className={"flex justify-between mb-[20px]"}>
        <Typography text={userData?.profile?.nickname} variant="h2" />
        <Link to={ROUTES.SETTINGS} className="flex items-end text-black">
          <Typography text="Изменить настройки" variant="h6" />
        </Link>
      </div>

      <div className={"flex justify-between mb-[10px]"}>
        <div className={"flex items-center gap-[20px]"}>
          <img
            className="w-[40px] h-[40px] rounded-[15px]"
            src={userData?.profile?.avatar ?? avatar_logo}
            alt=""
          />
          <Typography
            text={`${userData?.profile?.name ?? ""} ${
              userData?.profile?.surname ?? ""
            }`}
            variant="h4"
          />
        </div>
        <div className={"flex items-center"}>
          <Link to={`/${userData?.profile?.nickname}/follower`}>
            <span className="mr-[20px] flex flex-col items-center">
              <Typography text="Подписки" subtitle variant="h6" />
              <Typography text={userData?.follower} variant="h5" />
            </span>
          </Link>
          <Link to={`/${userData?.profile?.nickname}/followers`}>
            <span className="flex flex-col items-center">
              <Typography text="Подписчики" subtitle variant="h6" />
              <Typography text={userData?.followers} variant="h5" />
            </span>
          </Link>
        </div>
      </div>

      <div
        className={"text-12 font-400 leading-[20px]"}
        dangerouslySetInnerHTML={{
          __html: userData?.profile?.description?.replace(/\n/g, "</br>"),
        }}
      />
    </div>
  );
};
