import { ISignInForm, ISignUpForm } from "interfaces";
import { instance } from "./instance";

export const authAPI = {
  authMe(timeZone: string) {
    return instance.post(`/me?zoneId=${timeZone}`);
  },
  getTimeZones() {
    return instance.post("/constants/timezones");
  },

  register(data: ISignUpForm) {
    const fd = new FormData();
    fd.append("nickname", data.nickname);
    fd.append("email", data.email);
    fd.append("password", data.password);
    fd.append("passwordCheck", data.passwordCheck);
    fd.append("zoneId", data.zoneId);
    return instance.post(`/auth/join`, fd);
  },

  login({ username, password }: ISignInForm) {
    return instance.post("/auth/authenticate", {
      username: username,
      password: password,
    });
  },

  confirmRegister(token: string) {
    //TODO check me
    return instance.post("/auth/confirm", {
      token: token,
      email: "ivvwbpsolebzbye@candassociates.com",
      name: "pav",
    });
  },
};
