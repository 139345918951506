import React from "react";
import { Header } from "shared/ui";
import { ChangePasswordForm } from "features/user";
import { IChangePasswordForm } from "interfaces";

interface IProps {
  onSubmitHandler: (data: IChangePasswordForm) => void;
}

export const ChangePassword = ({ onSubmitHandler }: IProps) => {
  const formId = "changePassword";

  return (
    <div>
      <Header
        title={"Смена пароля"}
        rightTitle={"Сохранить"}
        onRight={() => {}}
        formId={formId}
      />
      <ChangePasswordForm onSubmitHandler={onSubmitHandler} formId={formId} />
    </div>
  );
};
