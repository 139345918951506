import { SET_IS_AUTH, SET_TIMEZONE, SET_USER } from "store/constants";
import { authActions } from "store/actions";
import { InferActionsType } from "store/store";
import { IUserProfile } from "interfaces";

export type IActions = InferActionsType<typeof authActions>;
export type IAuthReducer = typeof defaultState;

const defaultState = {
  isAuth: false as boolean,
  timeZones: "" as string,
  user: {} as IUserProfile,
};

export const authReducer = (
  state = defaultState,
  action: IActions
): IAuthReducer => {
  switch (action.type) {
    case SET_IS_AUTH:
    case SET_USER:
    case SET_TIMEZONE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
