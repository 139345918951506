import React from "react";
import cn from "classnames";

interface IProps {
  text: string | number;
  align?: "center" | "left" | "right";
  variant: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  subtitle?: boolean;
}

export const Typography = ({
  text,
  variant,
  subtitle = false,
  align = "left",
}: IProps) => {
  return (
    <div
      className={cn({
        "text-main_subtitle": subtitle,
        "text-left": align === "left",
        "text-center": align === "center",
        "text-right": align === "right",
        "font-medium text-20": variant === "h1",
        "font-medium text-16": variant === "h2",
        "font-medium text-14": variant === "h3",
        "font-normal text-14": variant === "h4",
        "font-normal text-12": variant === "h5",
        "font-normal text-10 ": variant === "h6",
      })}
    >
      {text}
    </div>
  );
};
