import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { ISignUpForm } from "interfaces";
import { useDispatch, useSelector } from "react-redux";
import { getDataForSignUp } from "store/selectors";
import { Button, Input } from "shared/form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SignUpSchema } from "app/constants";
import { appActions, authActions } from "store/actions";

interface IProps {
  onSubmitHandler: (data: ISignUpForm) => void;
}

export const SignUpForm = ({ onSubmitHandler }: IProps) => {
  const dispatch = useDispatch();
  const timeZones = useSelector(getDataForSignUp);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ISignUpForm>({
    resolver: yupResolver(SignUpSchema),
  });

  useEffect(() => {
    if (Object.keys(errors).length) {
      const transformErrors = [];
      for (const [, value] of Object.entries(errors)) {
        transformErrors.push(`${value.message} </br>`);
      }
      dispatch(
        appActions.setModalAC({
          title: "Проверьте правильность введенных данных",
          text: transformErrors.toString().replace(/,/gi, ""),
        })
      );
    }
  }, [dispatch, errors]);

  useEffect(() => {
    dispatch(
      authActions.setTimezoneAC(
        Intl.DateTimeFormat().resolvedOptions().timeZone
      )
    );
  }, []);

  const onSubmit = (data: ISignUpForm) => {
    const dataWithTZ = { ...data, zoneId: timeZones };
    onSubmitHandler(dataWithTZ);
  };

  return (
    <form
      className="w-full grid items-center gap-y-[20px]"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Input label="E-mail" name={"email"} control={control} />
      <Input label="Nickname" name={"nickname"} control={control} />
      <Input
        label="Дата рождения"
        name={"birthday"}
        type={"date"}
        control={control}
      />
      <Input
        label="Пароль"
        name={"password"}
        type={"password"}
        control={control}
      />
      <Input
        label="Повторите пароль"
        name={"passwordCheck"}
        type={"password"}
        control={control}
      />
      <Button className={"bg-main_blue_gb"} label={"Зарегистрироваться"} />
    </form>
  );
};
