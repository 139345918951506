import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createComment, getPostById } from "store/thunks";
import { getDataForPostPage } from "store/selectors";
import { postActions } from "store/actions";
import { IPost } from "interfaces";
import { Comment, CommentInput, PostCard } from "features/post";

export const Post = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { post, comments, userinfo } = useSelector(getDataForPostPage);
  useEffect(() => {
    fetch();
    return () => {
      dispatch(postActions.setPostAC({} as IPost));
    };
  }, [id]);

  const fetch = async () => {
    if (id) {
      await dispatch(getPostById(id));
    }
  };

  const sendCommentHandler = (comment: string) => {
    if (id) dispatch(createComment(id, comment));
  };

  if (!post?.id) {
    return null;
  }

  return (
    <div className={"flex h-full flex-col justify-between"}>
      <div className={"flex flex-col"}>
        <PostCard isMyPost={userinfo?.nickname === post?.author} item={post} />
        <div className={"flex flex-col py-[12px]"}>
          <div className={"px-[20px]"}>
            <div className={"text-10 text-black font-medium mb-[5px]"}>
              {post.author}
            </div>
            <div className={"text-10 text-black font-normal mb-[7px]"}>
              <div
                dangerouslySetInnerHTML={{
                  __html: post?.description?.replace(/\n/g, "<br />"),
                }}
              />
            </div>
            <div className={"text-10 text-black opacity-70 mb-[4px]"}>
              {`Комментарии (${comments?.length})`}
            </div>
            {comments?.map((i) => (
              <Comment key={i.id} comment={i} />
            ))}
          </div>
        </div>
      </div>
      <CommentInput
        ownerAvatar={post.avatarOwner}
        onSend={sendCommentHandler}
      />
    </div>
  );
};
