import { appActions } from "store/actions";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const commonThunkCreator = (operation: any, dispatch: any) => {
  const tryCatched = withTryCatch(operation, dispatch);
  return tryCatched();
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const withTryCatch = (operation: any, dispatch: any) => {
  return async () => {
    try {
      return await operation();
    } catch (e) {
      dispatch(appActions.setModalAC({ title: "error", text: "error" }));
      console.log("errorhandler", e);
    }
    return null;
  };
};
