import { Action, applyMiddleware, combineReducers, createStore } from "redux";
import thunkMiddleWare, { ThunkAction } from "redux-thunk";
import {
  appReducer,
  authReducer,
  feedsReducer,
  postReducer,
  profileReducer,
} from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension";

export const rootReducer = combineReducers({
  auth: authReducer,
  app: appReducer,
  profile: profileReducer,
  post: postReducer,
  feeds: feedsReducer,
});

type PropertiesType<T> = T extends { [key: string]: infer U } ? U : never;
export type InferActionsType<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends { [key: string]: (...args: any[]) => any }
> = ReturnType<PropertiesType<T>>;

export type BaseThunkType<A extends Action, R = Promise<void>> = ThunkAction<
  R,
  RootStateType,
  unknown,
  A
>;
export type RootStateType = ReturnType<typeof rootReducer>;

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunkMiddleWare))
);
