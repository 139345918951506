import React from "react";
import { ReactComponent as RightArrow } from "assets/icons/rightArrow/rightArrow.svg";
import user_avatar from "assets/images/user_avatar.png";
import { Typography } from "shared/ui";
import { Link } from "react-router-dom";
import { IUserData } from "interfaces";
import { Button } from "shared/form";

interface IProps {
  userData: IUserData;
  onPressBack: () => void;
  onPressFollowHandler: () => void;
  onPressUnfollowHandler: () => void;
}

export const PublicProfileInfo = ({
  userData,
  onPressFollowHandler,
  onPressUnfollowHandler,
  onPressBack,
}: IProps) => {
  return (
    <div className={"p-[20px]"}>
      <div className="flex items-center mb-[21px]" onClick={onPressBack}>
        <span className="mr-[10px]">
          <RightArrow />
        </span>
        <Typography text={userData?.profile?.nickname} variant="h2" />
      </div>
      <div className="flex justify-between mb-[9px]">
        <div className="flex justify-between items-center">
          <img
            className="w-[40px] h-[40px] rounded-[15px] gap-[15px]"
            src={userData?.profile?.avatar ?? user_avatar}
            alt=""
          />
          <span>
            <Typography
              text={`${userData?.profile?.name ?? ""} ${
                userData?.profile?.surname ?? ""
              }`}
              variant="h4"
            />
          </span>
        </div>
        <Link to={`/${userData?.profile?.nickname}/followers`}>
          <span className="flex flex-col items-center">
            <Typography text="Подписчики" subtitle variant="h6" />
            <Typography text={userData?.followers} variant="h5" />
          </span>
        </Link>
      </div>
      <div className="flex justify-between items-end">
        <div
          className={"text-12 font-400 leading-[20px]"}
          dangerouslySetInnerHTML={{
            __html: userData.profile.description?.replace(/\n/g, "</br>"),
          }}
        />
        <Button
          className={`py-[3px] rounded-[6px] text-12 ${
            userData?.isIfollower ? "bg-main_blue_text" : "bg-black"
          }`}
          label={!userData?.isIfollower ? "Подписаться" : "Отписаться"}
          onClick={
            !userData?.isIfollower
              ? onPressFollowHandler
              : onPressUnfollowHandler
          }
        />
      </div>
    </div>
  );
};
