import React, { useEffect, useState } from "react";
import { Typography } from "shared/ui";
import { ReactComponent as RightArrow } from "assets/icons/rightArrow/rightArrow.svg";

interface IProps {
  open: boolean;
  onClose: () => void;
  onPressSend: (data: string) => void;
}

export const ComplainModal = ({ open, onClose, onPressSend }: IProps) => {
  const [complain, setComplain] = useState<string>("");

  useEffect(() => {
    setComplain("");
  }, [open]);

  const onCloseHandler = () => {
    onClose();
  };

  const onPressSendHandler = () => {
    onPressSend(complain);
  };

  if (!open) {
    return null;
  }
  return (
    <div
      onClick={onCloseHandler}
      className={"fixed top-0 right-[1px] z-50 w-full h-screen bg-modal_bg"}
    >
      <div
        className={"flex items-center justify-center w-full h-screen px-[5%]"}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className={
            "w-full flex gap-[25px] px-[12px] pt-[15px] pb-[20px] rounded-[20px] items-center justify-center bg-white"
          }
        >
          <div className={"flex flex-col gap-[20px] items-center w-full"}>
            <div className={"w-full flex items-center justify-between"}>
              <span onClick={onCloseHandler} className="mr-[10px]">
                <RightArrow />
              </span>
              <Typography
                text={"Пожаловаться"}
                variant={"h2"}
                align={"center"}
              />
              <div />
            </div>
            <textarea
              autoFocus
              placeholder={"Опишите причину:"}
              className={
                "border-[#45B4FA] min-h-[300px] px-[10px] py-[8px] text-12 border-[1px] w-full resize-none"
              }
              value={complain}
              onChange={(e) => setComplain(e.target.value)}
            />
            <button
              onClick={onPressSendHandler}
              disabled={!complain}
              className={
                "w-1/2 text-white py-[8px] bg-main_blue_gb rounded-[6px] disabled:opacity-70 "
              }
            >
              Отправить
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
