import { commonThunkCreator } from "shared/utils";
import { appActions, feedActions, postActions } from "store/actions";
import { BaseThunkType, InferActionsType } from "store/store";
import { feedsAPI, fileAPI, postAPI } from "api";
import { COMPLAIN_TYPE } from "app/constants";

type ThunkType = BaseThunkType<
  InferActionsType<typeof postActions | typeof feedActions | typeof appActions>
>;

export const createPost =
  (file: File, description: string, callBack: () => void): ThunkType =>
  async (dispatch) => {
    await commonThunkCreator(async () => {
      dispatch(appActions.setLoaderAC(true));
      const image = await fileAPI.uploadFile(file);
      await postAPI.createPost({
        description: description,
        fileId: image.data.id,
      });
      const res = await feedsAPI.getFeeds(0);
      dispatch(feedActions.setFeedsAC(res.data.body?.content));
      dispatch(postActions.setTotalCountAC(res.data?.body?.totalElements));
      callBack();
    }, dispatch);
    dispatch(appActions.setLoaderAC(false));
  };

export const savePost =
  (id: string, description: string, callBack: () => void): ThunkType =>
  async (dispatch) => {
    await commonThunkCreator(async () => {
      dispatch(appActions.setLoaderAC(true));
      await postAPI.changePost({
        description: description,
        id: id,
      });
      callBack();
    }, dispatch);
    dispatch(appActions.setLoaderAC(false));
  };

export const getPostById =
  (post_id: string): ThunkType =>
  async (dispatch) => {
    await commonThunkCreator(async () => {
      dispatch(appActions.setLoaderAC(true));

      const post = await postAPI.getPostById(post_id);
      const comments = await postAPI.getComments(post_id);
      dispatch(postActions.setPostAC(post?.data?.body));
      dispatch(postActions.setCommentAC(comments.data?.body?.content));
    }, dispatch);
    dispatch(appActions.setLoaderAC(false));
  };

export const likeTC =
  (id: string): ThunkType =>
  async (dispatch, getState) => {
    await commonThunkCreator(async () => {
      try {
        const res = await postAPI.likePost(id);
        if (res.data.status) {
          const feeds = getState().feeds?.feeds?.map((i) =>
            i.id === res.data.body.id
              ? {
                  ...i,
                  ...res.data.body,
                }
              : i
          );
          dispatch(feedActions.setFeedsAC(feeds));
          dispatch(postActions.setPostAC(res.data.body));
        }
      } finally {
        dispatch(appActions.setInitializeAC(true));
      }
    }, dispatch);
  };
export const dislikeTC =
  (id: string): ThunkType =>
  async (dispatch, getState) => {
    await commonThunkCreator(async () => {
      try {
        const res = await postAPI.dislikePost(id);
        if (res.data.status) {
          const feeds = getState().feeds?.feeds?.map((i) =>
            i.id === id
              ? {
                  ...i,
                  ...res.data.body,
                }
              : i
          );
          dispatch(feedActions.setFeedsAC(feeds));
          dispatch(postActions.setPostAC(res.data.body));
        }
      } finally {
        dispatch(appActions.setInitializeAC(true));
      }
    }, dispatch);
  };

export const createComment =
  (id: string, comment: string): ThunkType =>
  async (dispatch) => {
    await commonThunkCreator(async () => {
      try {
        await postAPI.addComment(id, comment);
        await dispatch(getPostById(id));
      } finally {
        dispatch(appActions.setInitializeAC(true));
      }
    }, dispatch);
  };

export const sendComplain =
  (
    post_id: string,
    type: COMPLAIN_TYPE,
    complain: string,
    callback: () => void
  ): ThunkType =>
  async (dispatch) => {
    await commonThunkCreator(async () => {
      dispatch(appActions.setLoaderAC(true));
      await postAPI.sendComplain(post_id, complain, type);
      callback();
    }, dispatch);
    dispatch(appActions.setLoaderAC(false));
  };
