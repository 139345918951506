import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ChangePasswordSchema } from "app/constants";
import { Input } from "shared/form";
import { IChangePasswordForm } from "interfaces";
import { appActions } from "store/actions";

interface IProps {
  formId: string;
  onSubmitHandler: (data: IChangePasswordForm) => void;
}

export const ChangePasswordForm = ({ formId, onSubmitHandler }: IProps) => {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IChangePasswordForm>({
    resolver: yupResolver(ChangePasswordSchema),
  });
  useEffect(() => {
    if (Object.keys(errors).length) {
      const transformErrors = [];
      for (const [, value] of Object.entries(errors)) {
        transformErrors.push(`${value.message} </br>`);
      }
      dispatch(
        appActions.setModalAC({
          title: "Проверьте правильность введенных данных",
          text: transformErrors.toString().replace(/,/gi, ""),
        })
      );
    }
  }, [dispatch, errors]);
  const onSubmit = (data: IChangePasswordForm) => {
    if (!!errors) {
      onSubmitHandler(data);
    }
  };

  return (
    <form
      id={formId}
      className="flex flex-col gap-y-[20px] mx-[20px] mt-[40px]"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Input
        label="Старый пароль"
        name={"oldPassword"}
        control={control}
        type={"password"}
      />
      <Input
        label="Новый пароль"
        name={"newPassword"}
        control={control}
        type={"password"}
      />
      <Input
        label="Повторите новый пароль"
        name={"newPasswordAgain"}
        control={control}
        type={"password"}
      />
    </form>
  );
};
