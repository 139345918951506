import React, { ChangeEvent, useRef, useState } from "react";
import { Header, Typography } from "shared/ui";
import { Link } from "react-router-dom";
import { SettingsFooter } from "shared/ui/settingsFooter";
import { ROUTES } from "app/constants";
import { SettingsForm } from "features/user";
import { ISettingsForm, IUserProfile } from "interfaces";
import user_avatar from "assets/images/user_avatar.png";
import { useDispatch } from "react-redux";
import { changeAvatarTC } from "../../../store/thunks";

interface IProps {
  user: IUserProfile;
  onSubmitHandler: (data: ISettingsForm) => void;
}

export const Settings = ({ user, onSubmitHandler }: IProps) => {
  const formId = "settingsForm";
  const dispatch = useDispatch();
  const ref = useRef<any>();
  const [avatar, setAvatar] = useState<File | null>(null);

  const onChangeAvatarHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files) {
      setAvatar(e.currentTarget.files[0]);
      dispatch(changeAvatarTC(e.currentTarget.files[0]));
    }
  };

  return (
    <div>
      <Header title={"Настройки"} rightTitle={"Сохранить"} formId={formId} />
      <div className="flex items-center justify-center flex-col gap-[10px] my-[20px]">
        <img
          className="h-[80px] object-cover w-[80px] rounded-[80px]"
          src={
            avatar
              ? URL.createObjectURL(avatar)
              : user?.avatar
              ? user?.avatar
              : user_avatar
          }
          alt=""
        />
        <input
          type={"file"}
          hidden
          ref={ref}
          onChange={onChangeAvatarHandler}
        />
        <span
          onClick={() => ref.current.click()}
          className={"text-12 cursor-pointer font-400 leading-[14px]"}
        >
          Изменить фото
        </span>
      </div>
      <SettingsForm
        user={user}
        onSubmitHandler={onSubmitHandler}
        formId={formId}
      />
      <SettingsFooter
        title={"Изменить пароль"}
        link={ROUTES.SETTING_CHANGE_PASSWORD}
      />
    </div>
  );
};
