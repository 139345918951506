import React from "react";
import cn from "classnames";

interface IProps {
  label: string;
  appearance: "subscribe" | "unsubscribe";
  onClick: (nickname: string) => void;
  nickname: string;
}

export const ButtonSubscribeOrUnsubscribe = ({
  label,
  onClick,
  nickname,
  appearance,
}: IProps) => {
  return (
    <button
      className={cn(
        "text-white w-full rounded-[20px]",
        {
          ["bg-main_black_gb text-12 py-[3px] px-[35px] rounded-md font-medium flex items-center"]:
            appearance === "subscribe",
        },
        {
          ["bg-main_blue_gb text-12 py-[3px] px-[35px] rounded-md font-medium flex items-center"]:
            appearance === "unsubscribe",
        }
      )}
      onClick={() => onClick(nickname)}
    >
      {label}
    </button>
  );
};
