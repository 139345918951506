import React from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "app/constants/routes";
import { ReactComponent as MainLogo } from "assets/icons/logo_blue_main.svg";
import { Button, Logo } from "shared/ui";

export const PreLogin = () => {
  const navigate = useNavigate();
  const onPressSignInHandler = () => {
    navigate(ROUTES.SIGN_IN);
  };
  const onPressSignUpHandler = () => {
    navigate(ROUTES.SIGN_UP);
  };

  return (
    <div className={"h-screen w-screen flex justify-center items-center"}>
      <div
        className={
          "flex flex-col max-w-[800px] justify-center items-center w-full px-[21px]"
        }
      >
        <MainLogo className={"pb-[20px]"} />
        <Logo />
        <div className="grid gap-y-[30px] w-full">
          <Button onClick={onPressSignInHandler} label={"Войти"} />
          <Button
            appearance={"primary"}
            onClick={onPressSignUpHandler}
            label={"Регистрация"}
          />
        </div>
      </div>
    </div>
  );
};
