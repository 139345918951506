import React from "react";
import { SignUpForm } from "features/auth";
import { Logo } from "shared/ui";
import { ISignUpForm } from "interfaces";

interface IProps {
  onSubmitHandler: (data: ISignUpForm) => void;
}

export const SignUp = ({ onSubmitHandler }: IProps) => {
  return (
    <div className={"w-screen h-screen flex justify-center items-center"}>
      <div
        className={
          "flex flex-col justify-center max-w-[800px] items-center w-full px-[21px] py-[40px]"
        }
      >
        <Logo />
        <SignUpForm onSubmitHandler={onSubmitHandler} />
      </div>
    </div>
  );
};
