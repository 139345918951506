import React, { ButtonHTMLAttributes, DetailedHTMLProps } from "react";
import cn from "classnames";

interface IProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  label: string;
  appearance?: "default" | "primary" | "subscribe" | "unsubscribe";
  type?: "submit" | "reset" | "button" | undefined;
  fullWidth?: boolean;
}

export const Button = ({
  label,
  type = "submit",
  appearance = "default",
  fullWidth = true,
  ...rest
}: IProps) => {
  return (
    <button
      className={cn(
        "text-white rounded-[20px]",
        { ["w-full"]: fullWidth },
        { ["bg-main_blue_gb py-[14px] text-20"]: appearance === "primary" },
        {
          ["bg-main_black_gb text-12 py-[3px] px-[35px] rounded-md font-medium flex items-center"]:
            appearance === "subscribe",
        },
        {
          ["bg-main_blue_gb text-12 py-[3px] px-[35px] rounded-md font-medium flex items-center"]:
            appearance === "unsubscribe",
        },
        {
          ["bg-main_black_gb text-20 py-[14px] disabled:opacity-70 "]:
            appearance === "default",
        }
      )}
      type={type}
      {...rest}
    >
      {label}
    </button>
  );
};
