import React from "react";
import { Link } from "react-router-dom";
import { IPost } from "interfaces";
import { EmptyPage } from "shared/ui";

interface IProps {
  isEmpty?: boolean;
  posts: IPost[];
}

export const Posts = ({ posts, isEmpty = false }: IProps) => {
  if (isEmpty) {
    return <EmptyPage text={"Пока нет записей"} />;
  }
  return (
    <div className="grid grid-cols-2">
      {posts?.map((post) => {
        return (
          <Link
            className={"flex items-center"}
            key={post.id}
            to={`/p/${post.id}`}
          >
            <img src={post.filePath} alt="" />
          </Link>
        );
      })}
    </div>
  );
};
