import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { ISignInForm } from "interfaces";
import { useDispatch } from "react-redux";
import { appActions } from "store/actions";
import { yupResolver } from "@hookform/resolvers/yup";
import { SignInSchema } from "app/constants";
import { Button, Input } from "shared/form";

interface IProps {
  loader: boolean;
  onSubmitHandler: (data: ISignInForm) => void;
}

export const SignInForm = ({ loader, onSubmitHandler }: IProps) => {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ISignInForm>({
    resolver: yupResolver(SignInSchema),
  });

  useEffect(() => {
    if (Object.keys(errors).length) {
      const transformErrors = [];
      for (const [, value] of Object.entries(errors)) {
        transformErrors.push(`${value.message} </br>`);
      }
      dispatch(
        appActions.setModalAC({
          title: "Проверьте правильность введенных данных",
          text: transformErrors.toString().replace(/,/gi, ""),
        })
      );
    }
  }, [dispatch, errors]);

  const onSubmit = (data: ISignInForm) => {
    onSubmitHandler(data);
  };

  return (
    <form
      className="w-full grid items-center gap-y-[20px]"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Input name={"username"} control={control} label={"E-mail"} />
      <Input
        type={"password"}
        name={"password"}
        control={control}
        label={"Пароль"}
      />

      <Button disabled={loader} label={"Войти"} />
    </form>
  );
};
