export const transformDate = (date: string) => {
  const fullDate = new Date(date);
  const day = fullDate.getDate();
  const month = fullDate.getMonth() + 1;
  const year = fullDate.getFullYear();
  const hours = fullDate.getHours();
  const minutes = fullDate.getMinutes();
  if (!!day || !!month || !!year || !!hours || !!minutes) {
    return `
    ${day < 9 ? `0${day}` : day}.${month < 9 ? `0${month}` : month}.${year}, 
    ${hours < 9 ? `0${hours}` : hours}:${
      minutes < 9 ? `0${minutes}` : minutes
    }`;
  } else {
    return "";
  }
};
