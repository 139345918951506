import React, { useEffect } from "react";
import { Input } from "shared/form";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { ISettingsForm, IUserProfile } from "interfaces";
import { yupResolver } from "@hookform/resolvers/yup";
import { SettingsSchema } from "app/constants";
import { appActions } from "store/actions";
import { Textarea } from "../../../shared/form/textarea";

interface IProps {
  user: IUserProfile;
  formId: string;
  onSubmitHandler: (data: ISettingsForm) => void;
}

export const SettingsForm = ({ user, formId, onSubmitHandler }: IProps) => {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ISettingsForm>({
    resolver: yupResolver(SettingsSchema),
  });
  useEffect(() => {
    if (Object.keys(errors).length) {
      const transformErrors = [];
      for (const [, value] of Object.entries(errors)) {
        transformErrors.push(`${value.message} </br>`);
      }
      dispatch(
        appActions.setModalAC({
          title: "Проверьте правильность введенных данных",
          text: transformErrors.toString().replace(/,/gi, ""),
        })
      );
    }
  }, [dispatch, errors]);
  const onSubmit = (data: ISettingsForm) => {
    if (!!errors) {
      onSubmitHandler(data);
    }
  };
  return (
    <form
      id={formId}
      className="flex flex-col gap-y-[20px] mx-[20px]"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Input
        defaultValue={user?.name ?? ""}
        label="Имя"
        name={"name"}
        control={control}
      />
      <Input
        defaultValue={user?.surname ?? ""}
        label="Фамилия"
        name={"surname"}
        control={control}
      />
      <Input
        defaultValue={user?.nickname ?? ""}
        label="Nickname"
        name={"nickname"}
        control={control}
      />
      <Input
        defaultValue={user?.email ?? ""}
        label="E-mail"
        name={"email"}
        control={control}
      />
      <Input
        defaultValue={user.birthday ?? ""}
        label="День рождения"
        name={"birthday"}
        type={"date"}
        control={control}
      />
      <Input
        defaultValue={user?.phone ?? ""}
        label="Телефон"
        name={"phone"}
        control={control}
      />
      <Textarea
        maxRows={3}
        defaultValue={user?.description ?? ""}
        label="Описание профиля"
        name={"description"}
        control={control}
      />
    </form>
  );
};
