import React from "react";
import loader from "assets/icons/loader.png";

interface IProps {
  loading: boolean;
}

export const Loader = ({ loading }: IProps) => {
  if (!loading) {
    return null;
  }
  return (
    <div className={"fixed top-0 left-0 h-screen w-full z-[1000]"}>
      <div
        className={
          "h-screen flex justify-center bg-modal_bg items-center w-full"
        }
      >
        <div className={"flex items-center"}>
          <img
            alt={""}
            src={loader}
            className={"fill-white motion-reduce:hidden animate-spin"}
          />
        </div>
      </div>
    </div>
  );
};
