import { RootStateType } from "store/store";

export const getAppData = (state: RootStateType) => ({
  isInit: state.app.isInit,
  modal: state.app.modal,
  isAuth: state.auth.isAuth,
  loader: state.app.loader,
  openNewPostModal: state.post.openNewPostModal,
});

export const getLoader = (state: RootStateType) => ({
  loader: state.app.loader,
});
