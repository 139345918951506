import * as yup from "yup";

export const SignInSchema = yup.object({
  username: yup
    .string()
    .required("Поле обязательно для заполнения")
    .max(50, "E-mail не может содержать более 50 символов"),

  password: yup
    .string()
    .required("Поле обязательно для заполнения")
    .min(4, "Пароль должен содержать не менее 4 символов")
    .matches(/[a-zA-Z]/, "Пароль должен содержать латинскую букву"),
});

export const SignUpSchema = yup.object({
  nickname: yup
    .string()
    .required("Поле обязательно для заполнения")
    .min(6, "Никнейм не может быть короче 6 символов")
    .max(26, "Никнейм не может быть длиннее 26 символов")
    .matches(/[a-zA-Z]/, "Никнейм должен состоять из латинского алфавита"),

  email: yup
    .string()
    .required("Поле обязательно для заполнения")
    .email("Введите действительный E-mail")
    .max(50, "E-mail не может содержать более 50 символов"),
  birthday: yup.string().required("Поле обязательно для заполнения"),
  password: yup
    .string()
    .required("Поле обязательно для заполнения")
    .min(4, "Пароль должен содержать не менее 4 символов")
    .matches(/[a-zA-Z]/, "Пароль должен содержать латинскую букву"),

  passwordCheck: yup
    .string()
    .required("Повторите введенный пароль")
    .oneOf([yup.ref("password")], "Пароли не совпадают"),
});
export const SettingsSchema = yup.object({
  name: yup.string().required("Поле Имя обязательно для заполнения"),
  surname: yup.string().required("Поле Фамилия обязательно для заполнения"),
  nickname: yup.string().required("Поле Никнейм обязательно для заполнения"),
  email: yup
    .string()
    .required("Поле E-mail обязательно для заполнения")
    .email("Введите действительный E-mail")
    .max(50, "E-mail не может содержать более 50 символов"),
  birthday: yup
    .string()
    .required("Поле День Рождение обязательно для заполнения"),
  phone: yup.string().required("Поле Телефон обязательно для заполнения"),
  about: yup.string(),
});
export const ChangePasswordSchema = yup.object({
  oldPassword: yup
    .string()
    .required("Поле Старый пароль обязательно для заполнения"),
  newPassword: yup
    .string()
    .required("Поле Новый пароль обязательно для заполнения")
    .min(4, "Пароль должен содержать не менее 4 символов")
    .matches(/[a-zA-Z]/, "Пароль должен содержать латинскую букву"),
  newPasswordAgain: yup
    .string()
    .required("Поле Повторите пароль обязательно для заполнения")
    .oneOf([yup.ref("newPassword")], "Пароли не совпадают"),
});
