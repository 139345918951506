import { SET_MODAL, SET_IS_INIT, SET_LOADER } from "store/constants";
import { IModal } from "interfaces";

export const appActions = {
  setInitializeAC: (isInit: boolean) => ({
    type: SET_IS_INIT,
    payload: { isInit },
  }),
  setModalAC: (modal: IModal) => ({
    type: SET_MODAL,
    payload: { modal },
  }),
  setLoaderAC: (loader: boolean) => ({
    type: SET_LOADER,
    payload: { loader },
  }),
};
