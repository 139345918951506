import React, { useState } from "react";
import { ReactComponent as BackArrow } from "assets/icons/rightArrow/rightArrow.svg";
import { ReactComponent as AddPhotoIcon } from "assets/icons/addPhoto.svg";
import { Typography } from "shared/ui";
import { FileUploader } from "react-drag-drop-files";
import { acceptedImagesTypes, ROUTES } from "app/constants";
import { useNavigate } from "react-router-dom";

interface IProps {
  open: boolean;
  onClose: () => void;
}

export const NewPostModal = ({ open, onClose }: IProps) => {
  const navigate = useNavigate();

  const [file, setFile] = useState<File | null>(null);

  const handleChange = (file: File) => {
    setFile(file);
    if (file) {
      navigate(ROUTES.PREPARE_NEW_POST, { state: file });
      onCloseHandler();
      setFile(null);
    }
  };

  const onCloseHandler = () => {
    onClose();
    setFile(null);
  };

  if (!open) {
    return null;
  }
  return (
    <div
      onClick={onCloseHandler}
      className={"fixed top-0 left-0 z-50 w-full h-screen bg-modal_bg"}
    >
      <div
        className={"flex items-center justify-center w-full h-screen px-[5%]"}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className={
            "min-w-[150px] px-[12px] py-[15px] rounded-[20px] bg-white"
          }
        >
          <div className={"flex justify-between items-center"}>
            <BackArrow onClick={onCloseHandler} />
            <Typography text={"Создание публикации"} variant={"h2"} />
            <div />
          </div>
          <div
            className={"flex justify-center items-center w-[300px] h-[300px]"}
          >
            <FileUploader
              hoverTitle={"-"}
              handleChange={handleChange}
              name="file"
              types={acceptedImagesTypes}
            >
              <div
                className={
                  "flex flex-col justify-center items-center w-[300px] h-[300px]"
                }
              >
                <AddPhotoIcon className={"w-[50px] h-[50px] pb-[20px]"} />
                <div
                  className={
                    "cursor-pointer px-[40px] text-white rounded-[6px] bg-main_blue_gb py-[8px] text-14"
                  }
                >
                  Выбрать фото
                </div>
              </div>
            </FileUploader>
          </div>
        </div>
      </div>
    </div>
  );
};
