import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dislikeTC, getFeeds, likeTC } from "store/thunks";
import { getDataForFeeds } from "store/selectors";
import { FeedCard } from "features/feeds";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "app/constants";
import { appActions, feedActions, postActions } from "store/actions";
import Slider from "react-slick";
import { EmptyPage } from "shared/ui";

export const Feeds = () => {
  const { feeds, currentPostIndex, currentPage, totalCount } =
    useSelector(getDataForFeeds);
  const [loader, setLoader] = useState<boolean>(true);
  const [isFirst, setIsFirst] = useState<boolean>(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sliderRef = useRef<any>();
  const location = useLocation();

  useEffect(() => {
    if (feeds.length) {
      refetch();
    }
  }, [location.key]);

  useEffect(() => {
    if (currentPostIndex > 0) {
      sliderRef?.current?.slickGoTo(currentPostIndex);
    }
  }, []);

  useEffect(() => {
    initFetch();
  }, []);

  const refetch = async () => {
    dispatch(appActions.setLoaderAC(true));
    setLoader(true);
    dispatch(feedActions.setFeedsAC([]));
    await dispatch(getFeeds(0));
    setLoader(false);
    dispatch(appActions.setLoaderAC(false));
  };

  const initFetch = async () => {
    if (!feeds.length) {
      dispatch(appActions.setLoaderAC(true));
      await dispatch(getFeeds(currentPage));
      setLoader(false);
      dispatch(appActions.setLoaderAC(false));
    }
  };
  const onPressPostHandler = (id: string, index: number) => {
    dispatch(postActions.setCurrentPostIndexAC(index));
    navigate(`${ROUTES.POST}/${id}`);
  };
  const onPressLikeHandler = (id: string) => {
    dispatch(likeTC(id));
  };
  const scroll = useCallback(
    (y) => {
      if (y > 60) {
        return sliderRef?.current?.slickNext();
      }
      if (y < -60) {
        return sliderRef?.current?.slickPrev();
      }
    },
    [sliderRef]
  );
  useEffect(() => {
    window.addEventListener(
      "wheel",
      (e) => {
        scroll(e.deltaY);
      },
      true
    );
    return () => {
      window.removeEventListener("wheel", scroll, true);
    };
  }, [scroll]);
  const onPressDisLikeHandler = (id: string) => {
    dispatch(dislikeTC(id));
  };

  const setCurrentIndexHandler = (index: number, newIndex: number) => {
    newIndex === 0 ? setIsFirst(true) : setIsFirst(false);
    if (feeds.length - index === 2 && feeds.length !== totalCount) {
      dispatch(postActions.setCurrentPageAC(currentPage + 1));
      dispatch(getFeeds(currentPage + 1));
    }
  };
  // const refetch = async (direction: string) => {
  //   if (direction === "down" && isFirst) {
  //     setLoader(true);
  //     dispatch(feedActions.setFeedsAC([]));
  //     await dispatch(getFeeds(0));
  //     setLoader(false);
  //   }
  // };

  if (loader && feeds.length === 0) {
    return null;
  }
  if (!loader && feeds.length === 0) {
    return <EmptyPage text={"У вас пока нет новостей"} />;
  }

  const settings = {
    initialSlide: currentPostIndex,
    beforeChange: (index: number, newIndex: number) =>
      setCurrentIndexHandler(index, newIndex),
    swipe: true,
    //onSwipe: (direction: string) => refetch(direction),
    vertical: true,
    infinite: false,
    draggable: true,
    dots: false,
    verticalSwiping: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className={"overflow-hidden"}>
      <Slider ref={sliderRef} {...settings}>
        {feeds?.map((i, index) => (
          <FeedCard
            item={i}
            key={i.id}
            index={index}
            onPressPostHandler={onPressPostHandler}
            onPressLikeHandler={onPressLikeHandler}
            onPressDisLikeHandler={onPressDisLikeHandler}
          />
        ))}
      </Slider>
    </div>
  );
};
