import React from "react";
import { ROUTES } from "app/constants/routes";
import {
  SignInPage,
  SignUpPage,
  PreLogin,
  ConfirmEmail,
  ProfileContainer,
  Feeds,
  NewPost,
  SettingsPage,
  ChangePasswordPage,
  FollowersList,
  SubscriptionsList,
  Post,
  NotFound,
  EditePost,
  PrepareNewPost,
} from "pages";
import { Layout } from "shared/ui";

export const routes = (isAuth: boolean) => [
  {
    path: ROUTES.MAIN,
    element: isAuth ? <Layout /> : <PreLogin />,
    children: [
      { path: ROUTES.EMPTY, element: <Feeds /> },
      { path: ROUTES.PROFILE, element: <ProfileContainer /> },
      { path: ROUTES.NEW_POST, element: <NewPost /> },
      { path: ROUTES.PREPARE_NEW_POST, element: <PrepareNewPost /> },
      { path: `${ROUTES.EDITE_POST}/:id`, element: <EditePost /> },
      { path: `${ROUTES.POST}/:id`, element: <Post /> },
      { path: ROUTES.SETTINGS, element: <SettingsPage /> },
      { path: ROUTES.SETTING_CHANGE_PASSWORD, element: <ChangePasswordPage /> },
      { path: ROUTES.FOLLOWERS, element: <FollowersList /> },
      { path: ROUTES.SUBSCRIPTIONS, element: <SubscriptionsList /> },
    ],
  },
  //Auth

  { path: ROUTES.SIGN_IN, element: <SignInPage /> },
  { path: ROUTES.SIGN_UP, element: <SignUpPage /> },
  { path: ROUTES.CONFIRM, element: <ConfirmEmail /> },
  { path: ROUTES.PAGE_NOT_FOUND, element: <NotFound /> },
];
