import { IComment, IPost } from "interfaces";
import { postActions } from "store/actions";
import { InferActionsType } from "store/store";
import {
  SET_COMMENTS,
  SET_CURRENT_PAGE,
  SET_CURRENT_POST_INDEX,
  SET_OPEN_NEW_POST_MODAL,
  SET_POST,
  SET_TOTAL_COUNT,
} from "../constants";

export type IActions = InferActionsType<typeof postActions>;
export type IPostReducer = typeof defaultState;

const defaultState = {
  openNewPostModal: false as boolean,
  post: {} as IPost,
  comments: [] as IComment[],
  currentPostIndex: 0 as number,
  currentPage: 0 as number,
  totalCount: 0 as number,
};

export const postReducer = (
  state = defaultState,
  action: IActions
): IPostReducer => {
  switch (action.type) {
    case SET_OPEN_NEW_POST_MODAL:
    case SET_CURRENT_POST_INDEX:
    case SET_COMMENTS:
    case SET_POST:
    case SET_CURRENT_PAGE:
    case SET_TOTAL_COUNT:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
