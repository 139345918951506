import React, { DetailedHTMLProps, InputHTMLAttributes } from "react";
import { Control, useController } from "react-hook-form";

interface IProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label: string;
  name: string;
  defaultValue?: string;
  control: Control<any>;
}

export const Input = ({
  label,
  control,
  name,
  defaultValue = "",
  ...rest
}: IProps) => {
  const {
    field: { onChange, value },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    defaultValue,
  });
  return (
    <div className={"w-full"}>
      <div className={"relative w-full"}>
        <input
          onChange={onChange}
          id={name}
          value={value}
          name={name}
          placeholder={label}
          className={`input w-full pt-[30px] pb-[10px] px-[25px] peer text-16 placeholder-white focus:outline-none focus:border-blue`}
          {...rest}
        />
        <label
          htmlFor={name}
          className={
            "absolute left-[25px] top-[10%] text-12 lowercase text-main_subtitle transition-all text-main_subtitle peer-placeholder-shown:top-[30%] peer-placeholder-shown:normal-case peer-placeholder-shown:text-16 peer-focus:top-[10%] peer-focus:text-12 peer-focus:lowercase"
          }
        >
          {label}
        </label>
      </div>
    </div>
  );
};
