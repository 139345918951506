import React from "react";
import { Typography } from "../typography";

interface IProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  text?: string;
}

export const Modal = ({ title, text, open, onClose }: IProps) => {
  if (!open) {
    return null;
  }

  return (
    <div
      onClick={onClose}
      className={"fixed z-[100] top-0 left-0 z-50 w-full h-screen bg-modal_bg"}
    >
      <div
        className={"flex items-center justify-center w-full h-screen px-[5%]"}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className={
            "min-w-[150px] grid gap-[25px] px-[12px] pt-[24px] pb-[14px] rounded-[20px] items-center justify-center bg-white"
          }
        >
          <div className={"grid items-center justify-center gap-[12px]"}>
            <Typography align={"center"} text={title ?? ""} variant={"h2"} />
            <div
              className={"text-main_subtitle"}
              dangerouslySetInnerHTML={{ __html: text ?? "" }}
            />
          </div>
          <button onClick={onClose} className={"text-main_blue_text text-16"}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
};
