import React from "react";
import { IComment } from "interfaces";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "app/constants";

interface IProps {
  comment: IComment;
}

export const Comment = ({ comment }: IProps) => {
  const navigation = useNavigate();

  const goToProfileHandler = () => {
    navigation(`/${comment.author}`);
  };
  return (
    <div className={"flex gap-[5px] mb-[6px]"}>
      <div
        onClick={goToProfileHandler}
        className={"text-10 font-medium cursor-pointer"}
      >
        {comment.author}
      </div>
      <div className={"text-10 font-normal"}>{comment.message}</div>
    </div>
  );
};
