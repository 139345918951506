import React from "react";
import { IPost, IUserData } from "interfaces";
import { useNavigate } from "react-router-dom";
import { Posts, PublicProfileInfo } from "features/user";

export interface IProps {
  userData: IUserData;
  postsData: IPost[];
  follow: () => void;
  unfollow: () => void;
}

export const PublicProfile = ({
  userData,
  postsData,
  follow,
  unfollow,
}: IProps) => {
  const navigation = useNavigate();
  const onPressBack = () => {
    navigation(-1);
  };

  return (
    <div className={"flex flex-col h-full"}>
      <PublicProfileInfo
        onPressBack={onPressBack}
        onPressFollowHandler={follow}
        onPressUnfollowHandler={unfollow}
        userData={userData}
      />

      <Posts isEmpty={postsData.length === 0} posts={postsData} />
    </div>
  );
};
