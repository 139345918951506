import {
  SET_COMMENTS,
  SET_CURRENT_PAGE,
  SET_CURRENT_POST_INDEX,
  SET_OPEN_NEW_POST_MODAL,
  SET_POST,
  SET_TOTAL_COUNT,
} from "../constants";
import { IComment, IPost } from "interfaces";

export const postActions = {
  setOpenNewPostModalAC: (openNewPostModal: boolean) => ({
    type: SET_OPEN_NEW_POST_MODAL,
    payload: { openNewPostModal },
  }),
  setPostAC: (post: IPost) => ({
    type: SET_POST,
    payload: { post },
  }),
  setCommentAC: (comments: IComment[]) => ({
    type: SET_COMMENTS,
    payload: { comments },
  }),
  setCurrentPageAC: (currentPage: number) => ({
    type: SET_CURRENT_PAGE,
    payload: { currentPage },
  }),
  setTotalCountAC: (totalCount: number) => ({
    type: SET_TOTAL_COUNT,
    payload: { totalCount },
  }),
  setCurrentPostIndexAC: (currentPostIndex: number) => ({
    type: SET_CURRENT_POST_INDEX,
    payload: { currentPostIndex },
  }),
};
