import React from "react";
import { Outlet } from "react-router-dom";
import { BottomNavigation } from "./bottomNavigation";

interface IProps {
  children?: React.ReactNode;
}

export const LayoutContainer = ({ children }: IProps) => {
  return (
    <div className={"relative w-full h-full max-h-screen flex justify-center"}>
      <div className={"w-full h-full max-w-[800px] flex flex-col"}>
        <div className={"pb-[80px]"}>{children}</div>
        <BottomNavigation />
      </div>
    </div>
  );
};

export const Layout = () => {
  return (
    <LayoutContainer>
      <Outlet />
    </LayoutContainer>
  );
};
