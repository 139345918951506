import React from "react";
import { Typography } from "shared/ui";

interface IProps {
  open: boolean;
  onClose: () => void;
}

export const SuccessModal = ({ open, onClose }: IProps) => {
  if (!open) {
    return null;
  }
  return (
    <div
      onClick={onClose}
      className={"fixed top-0 right-[1px] z-50 w-full h-screen bg-modal_bg"}
    >
      <div
        className={"flex items-center justify-center w-full h-screen px-[5%]"}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className={
            "w-full flex gap-[25px] px-[12px] pt-[15px] pb-[20px] rounded-[20px] items-center justify-center bg-white"
          }
        >
          <div className={"flex flex-col gap-[20px] items-center w-full"}>
            <Typography
              text={"Спасибо, что сообщили нам об этом!"}
              variant={"h2"}
              align={"center"}
            />
            <button
              onClick={onClose}
              className={
                "w-1/2 text-white py-[8px] bg-main_blue_gb rounded-[6px] disabled:opacity-70 "
              }
            >
              Закрыть
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
