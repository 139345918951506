import React from "react";

interface IProps {
  text: string;
}

export const EmptyPage = ({ text }: IProps) => {
  return (
    <div className={"h-full text-black flex justify-center items-center"}>
      {text}
    </div>
  );
};
