import {
  SET_FOLLOWERS,
  SET_POSTS_DATA,
  SET_SUBSCRIPTIONS,
  SET_USER_DATA,
} from "store/constants";
import { IFollowers, IPost, IUserData } from "interfaces";

export const profileActions = {
  setUserData: (userData: IUserData) => ({
    type: SET_USER_DATA,
    payload: { userData },
  }),
  setPostsData: (postsData: Array<IPost>) => ({
    type: SET_POSTS_DATA,
    payload: { postsData },
  }),
  setFollowers: (followers: Array<IFollowers>) => ({
    type: SET_FOLLOWERS,
    payload: { followers },
  }),
  setSubscriptions: (subscriptions: Array<IFollowers>) => ({
    type: SET_SUBSCRIPTIONS,
    payload: { subscriptions },
  }),
};
