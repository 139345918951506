import React from "react";

export const Logo = () => {
  return (
    <h1
      className={"pb-[20px] pt-[20px]"}
      style={{
        fontFamily: "Permanent Marker",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 24,
      }}
    >
      {" "}
      ZDYMAK
    </h1>
  );
};
