import React from "react";
import { ReactComponent as LeftArrow } from "assets/icons/leftArrow/leftArrow.svg";
import { Typography } from "../typography";
import { Link, useNavigate } from "react-router-dom";
import { Toggle } from "../index";

interface IProps {
  title: string;
  toggle?: boolean;
  link?: string;
  onToggle?: () => void;
}

export const SettingsFooter = ({ title, toggle, link, onToggle }: IProps) => {
  const navigate = useNavigate();
  const onRight = () => navigate(`${link}`);
  return (
    <div
      className={
        "flex items-center justify-between mb-[18px] ml-[32px] mt-[18px]"
      }
    >
      {toggle ? (
        <>
          <Typography text={title} variant="h2" />
          <Toggle onToggle={onToggle} />
        </>
      ) : (
        <>
          <Link to={`${link}`} className="flex items-end text-black">
            <Typography text={title} variant="h2" />
          </Link>
          <span onClick={onRight} className="mr-[25px]">
            <LeftArrow />
          </span>
        </>
      )}
    </div>
  );
};
