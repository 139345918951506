import { appActions, authActions } from "store/actions";
import { BaseThunkType, InferActionsType } from "store/store";
import { commonThunkCreator } from "shared/utils";
import { ISignInForm, ISignUpForm } from "interfaces";
import { authAPI } from "api";
import { errors, ROUTES } from "app/constants";

type ThunkType = BaseThunkType<
  InferActionsType<typeof authActions | typeof appActions>
>;

export const authMeTC = (): ThunkType => async (dispatch) => {
  try {
    const timezones = await authAPI.getTimeZones();
    const timeZone = timezones.data.body.find(
      (i: any) => i === Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    const res = await authAPI.authMe(timeZone);
    dispatch(authActions.setIsAuthAC(res.data.status));
    dispatch(authActions.setUserAC(res.data.body));
  } catch (e: any) {
    console.log(e);
  } finally {
    dispatch(appActions.setInitializeAC(true));
  }
};

export const loginTC =
  (data: ISignInForm): ThunkType =>
  async (dispatch) => {
    await commonThunkCreator(async () => {
      dispatch(appActions.setLoaderAC(true));
      const res = await authAPI.login(data);
      if (res.data.success) {
        await dispatch(authMeTC());
      } else {
        dispatch(appActions.setModalAC(errors.incorrectSignInData));
      }
    }, dispatch);
    dispatch(appActions.setLoaderAC(false));
  };

export const registerTC =
  (data: ISignUpForm): ThunkType =>
  async (dispatch) => {
    await commonThunkCreator(async () => {
      dispatch(appActions.setLoaderAC(true));
      const register = await authAPI.register(data);
      if (!register.data.status) {
        dispatch(
          appActions.setModalAC({
            title: "Ошибка",
            text: register.data.message,
          })
        );
      } else if (register.data.status) {
        dispatch(
          appActions.setModalAC({
            title: register.data.body.title,
            text: register.data.body.description,
            navigateTo: ROUTES.SIGN_IN,
          })
        );
      }
    }, dispatch);
    dispatch(appActions.setLoaderAC(false));
  };

export const confirmEmailTC =
  (token: string): ThunkType =>
  async (dispatch) => {
    await commonThunkCreator(async () => {
      dispatch(appActions.setLoaderAC(true));
      const res = await authAPI.confirmRegister(token);
      if (res.data.status) {
        dispatch(
          appActions.setModalAC({
            title: res.data.body.title,
            text: res.data.body.description,
            navigateTo: ROUTES.SIGN_IN,
          })
        );
      }
    }, dispatch);
    dispatch(appActions.setLoaderAC(false));
  };
