import { SET_IS_AUTH, SET_TIMEZONE, SET_USER } from "store/constants";
import { IUserProfile } from "interfaces";

export const authActions = {
  setIsAuthAC: (isAuth: boolean) => ({
    type: SET_IS_AUTH,
    payload: { isAuth },
  }),
  setTimezoneAC: (timeZones: string) => ({
    type: SET_TIMEZONE,
    payload: { timeZones },
  }),
  setUserAC: (user: IUserProfile) => ({
    type: SET_USER,
    payload: { user },
  }),
};
