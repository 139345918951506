import React, { ButtonHTMLAttributes, DetailedHTMLProps } from "react";

interface IProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  label: string;
  loading?: boolean;
}

export const Button = ({ label, className, ...rest }: IProps) => {
  return (
    <button className={`button ${className}`} {...rest}>
      {label}
    </button>
  );
};
