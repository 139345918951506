import { SET_MODAL, SET_IS_INIT, SET_LOADER } from "store/constants";
import { appActions } from "store/actions";
import { InferActionsType } from "store/store";
import { IModal } from "interfaces";

export type IActions = InferActionsType<typeof appActions>;
export type IAppReducer = typeof defaultState;

const defaultState = {
  isInit: false as boolean,
  modal: {} as IModal,
  loader: false as boolean,
};

export const appReducer = (
  state = defaultState,
  action: IActions
): IAppReducer => {
  switch (action.type) {
    case SET_IS_INIT:
    case SET_MODAL:
    case SET_LOADER:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
