import { instance } from "./instance";
import { COMPLAIN_TYPE } from "../app/constants";

export const postAPI = {
  createPost(post: any) {
    return instance.post("/p/create", post);
  },
  changePost(post: any) {
    return instance.post("/p/update", post);
  },
  getPostById(post_id: string) {
    return instance.get(`/p/${post_id}`);
  },
  getComments(post_id: string) {
    return instance.post(`/p/${post_id}/c`, {
      page: 0,
      size: 100,
    });
  },
  likePost(post_id: string) {
    return instance.get(`/reaction/${post_id}?reaction=LIKE&targetType=POST`);
  },
  dislikePost(post_id: string) {
    return instance.get(
      `/reaction/${post_id}?reaction=DISLIKE&targetType=POST`
    );
  },
  addComment(post_id: string, comment: string) {
    return instance.post(`/p/c/save?postId=${post_id}&message=${comment}`);
  },
  sendComplain(post_id: string, complain: string, type: COMPLAIN_TYPE) {
    return instance.post(`/complaint`, {
      message: complain,
      targetId: post_id,
      type: type,
    });
  },
};
