import React from "react";

interface IProps {
  onToggle?: () => void;
}

export const Toggle = ({ onToggle }: IProps) => {
  return (
    <div className="flex items-center justify-center mr-[25px]">
      <label
        htmlFor="toggle"
        className="relative flex items-center cursor-pointer"
      >
        <input type="checkbox" id="toggle" className="sr-only peer" />
        <div
          onClick={onToggle}
          className="h-[15px] w-[29px] p-[1px] bg-main_subtitle rounded-full after:absolute
          after:bg-white after:h-[13px] after:w-[13px] after:rounded-full peer-checked:after:translate-x-full
          peer-checked:bg-main_blue_border after:transition-all after:duration-300"
        ></div>
      </label>
    </div>
  );
};
