import { instance } from "./instance";
import { IChangePasswordForm, ISettingsForm } from "../interfaces";

export const settingsAPI = {
  changeSettings(data: any) {
    return instance.post("/settings", data);
  },
  changePassword(data: IChangePasswordForm) {
    return instance.post("/password/change", data);
  },
  changeAvatar(photo: string) {
    return instance.post("/settings/avatar", { fileId: photo });
  },
};
