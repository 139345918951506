import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getDataForProfile } from "store/selectors";
import {
  followTC,
  getProfileDataTC,
  getUserPostsTC,
  unfollowTC,
} from "store/thunks";
import { PublicProfile } from "./publickProfile";
import { MyProfile } from "./myProfile";
import { PAGE_TYPE } from "store/constants";
import { profileActions } from "store/actions";
import { IUserData } from "interfaces";

export const ProfileContainer = () => {
  const dispatch = useDispatch();
  const { nickname } = useParams();
  const { userData, postsData, postPage, postSize } =
    useSelector(getDataForProfile);
  const isMyProfile = userData?.myprofile;

  useEffect(() => {
    if (nickname) {
      dispatch(getProfileDataTC(nickname));
      dispatch(getUserPostsTC(nickname, postPage, postSize));
    }
    return () => {
      dispatch(profileActions.setUserData({} as IUserData));
      dispatch(profileActions.setPostsData([]));
    };
  }, [nickname]);

  const follow = () => {
    if (nickname) {
      dispatch(followTC(nickname, PAGE_TYPE.PROFILE));
    }
  };
  const unfollow = () => {
    if (nickname) {
      dispatch(unfollowTC(nickname, PAGE_TYPE.PROFILE));
    }
  };

  if (!userData?.profile) {
    return null;
  }

  if (isMyProfile) {
    return <MyProfile userData={userData} postsData={postsData} />;
  }

  return (
    <PublicProfile
      postsData={postsData}
      userData={userData}
      follow={follow}
      unfollow={unfollow}
    />
  );
};
