import { instance } from "./instance";

export const profileAPI = {
  getUserData(nickname: string) {
    return instance.get(`/${nickname}`);
  },
  getUserPosts(nickname: string, page: number, size: string) {
    return instance.get(
      `/posts?nickname=${nickname}&page=${page}&size=${size}`
    );
  },
  getFollowers(
    nickname: string | undefined,
    page: number | undefined,
    size: string | undefined
  ) {
    return instance.post(`/${nickname}/followers?page=${page}&size=${size}`);
  },
  getSubscriptions(
    nickname: string | undefined,
    page: number | undefined,
    size: string | undefined
  ) {
    return instance.post(`/${nickname}/follower?page=${page}&size=${size}`);
  },
  follow(nickname: string | undefined) {
    return instance.post(`/${nickname}/follow`);
  },
  unfollow(nickname: string | undefined) {
    return instance.post(`/${nickname}/unfollow`);
  },
};
