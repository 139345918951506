import React from "react";
import { IPost, IUserData } from "interfaces";
import { MyProfileInfo, Posts } from "features/user";

interface IProps {
  userData: IUserData;
  postsData: IPost[];
}

export const MyProfile = ({ userData, postsData }: IProps) => {
  return (
    <div className="flex flex-col h-[calc(100%-8vh)]">
      <MyProfileInfo userData={userData} />
      <Posts isEmpty={postsData.length === 0} posts={postsData} />
    </div>
  );
};
