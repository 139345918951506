import React, { ChangeEvent, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Header } from "shared/ui";
import { useDispatch } from "react-redux";
import { savePost } from "store/thunks";
import { ROUTES } from "app/constants";

export const EditePost = () => {
  const location = useLocation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [image] = useState((location as any)?.state.filePath);
  const [description, setDescription] = useState<string>(
    (location as any)?.state.description
  );
  useEffect(() => {
    return () => {
      location.state = {};
    };
  }, []);

  const onPressBack = () => {
    navigation(-1);
  };

  const redirectAfterCreatePost = () => {
    navigation(`${ROUTES.POST}/${id}`, { replace: true });
  };

  const onChangeDescriptionHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.currentTarget.value);
  };

  const onPressAddPost = async () => {
    if (id) {
      await dispatch(savePost(id, description, redirectAfterCreatePost));
    }
  };

  return (
    <div className={"flex justify-center"}>
      <div className={"flex flex-col"}>
        <Header
          title={"Публикация"}
          onLeft={onPressBack}
          rightTitle={"Сохранить"}
          onRight={onPressAddPost}
        />
        <div className={"flex justify-center"}>
          <img src={image} alt="Thumb" />
        </div>
        <div className={"p-[20px]"}>
          <textarea
            placeholder={"Добавьте описание..."}
            className={
              "border-[#45B4FA] min-h-[170px] px-[10px] py-[8px] text-12 border-[1px] w-full resize-none"
            }
            value={description}
            onChange={onChangeDescriptionHandler}
          />
        </div>
      </div>
    </div>
  );
};
