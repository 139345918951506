import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { followTC, getSubscriptionsTC, unfollowTC } from "store/thunks";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  ButtonSubscribeOrUnsubscribe,
  EmptyPage,
  Header,
  Typography,
} from "shared/ui";
import { getDataForFollowersPage } from "store/selectors";
import { PAGE_TYPE } from "store/constants";
import user_avatar from "assets/images/user_avatar.png";

export const FollowersList = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { nickname } = useParams();
  const { subscriptions, userinfo, page, size, loader } = useSelector(
    getDataForFollowersPage
  );

  useEffect(() => {
    if (nickname) {
      dispatch(getSubscriptionsTC(nickname, page, size));
    }
  }, [nickname]);

  const onPressBack = () => {
    navigation(-1);
  };

  const follow = (userName: string) => {
    if (nickname) {
      dispatch(followTC(userName, PAGE_TYPE.FOLLOWERS, nickname, page, size));
    }
  };
  const unfollow = (userName: string) => {
    if (nickname) {
      dispatch(unfollowTC(userName, PAGE_TYPE.FOLLOWERS, nickname, page, size));
    }
  };

  return (
    <div className={"flex flex-col h-full"}>
      <Header title="Подписчики" onLeft={onPressBack} />
      {!loader && subscriptions.length === 0 ? (
        <EmptyPage text={"У вас пока подписчиков"} />
      ) : (
        <div>
          {subscriptions.map((user) => {
            return (
              <div
                key={user.nickname}
                className="flex justify-between px-20 py-10"
              >
                <Link to={`/${user.nickname}`}>
                  <div className="flex items-center">
                    <img
                      className="w-[40px] h-[40px] mr-10 rounded-2xl"
                      src={user?.avatar ?? user_avatar}
                      alt=""
                    />
                    <Typography text={user.nickname} variant="h4" />
                  </div>
                </Link>
                {userinfo.nickname !== user.nickname && (
                  <div className="flex items-center w-[140px]">
                    <ButtonSubscribeOrUnsubscribe
                      onClick={user.follower ? unfollow : follow}
                      label={user.follower ? "Отписаться" : "Подписаться"}
                      appearance={user.follower ? "unsubscribe" : "subscribe"}
                      nickname={user.nickname}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
