import {
  SET_FOLLOWERS,
  SET_POSTS_DATA,
  SET_SUBSCRIPTIONS,
  SET_USER_DATA,
} from "store/constants";
import { profileActions } from "store/actions";
import { InferActionsType } from "store/store";
import { IFollowers, IUserData } from "interfaces/profile";
import { IPost } from "interfaces";

export type IActions = InferActionsType<typeof profileActions>;
export type IProfileReducer = typeof defaultState;

const defaultState = {
  userData: {} as IUserData,
  postsData: [] as Array<IPost>,
  followers: [] as Array<IFollowers>,
  subscriptions: [] as Array<IFollowers>,
  page: 0 as number,
  postPage: 0 as number,
  size: "10" as string,
  postSize: "10" as string,
};

export const profileReducer = (
  state = defaultState,
  action: IActions
): IProfileReducer => {
  switch (action.type) {
    case SET_USER_DATA:
    case SET_FOLLOWERS:
    case SET_SUBSCRIPTIONS:
    case SET_POSTS_DATA:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
