import React from "react";
import { IFeed } from "interfaces";
import { ReactComponent as LikeIcon } from "assets/icons/like.svg";
import { ReactComponent as DisLikeIcon } from "assets/icons/dislike.svg";
import { ReactComponent as CommentsIcon } from "assets/icons/comments.svg";
import { Typography } from "shared/ui";
import { transformDate } from "shared/utils";
import { useNavigate } from "react-router-dom";
import { postActions } from "store/actions";
import { useDispatch } from "react-redux";
import user_avatar from "assets/images/user_avatar.png";

interface IProps {
  item: IFeed;
  index: number;
  onPressPostHandler: (id: string, index: number) => void;
  onPressLikeHandler: (id: string) => void;
  onPressDisLikeHandler: (id: string) => void;
}

export const FeedCard = ({
  item,
  onPressDisLikeHandler,
  index,
  onPressPostHandler,
  onPressLikeHandler,
}: IProps) => {
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const goToProfileHandler = () => {
    dispatch(postActions.setCurrentPostIndexAC(index));
    navigation(`/${item?.author}`);
  };

  return (
    <div
      id={index.toString()}
      className={"flex flex-col w-full h-full overflow-hidden"}
    >
      <div className={"h-[80vh] overflow-hidden"}>
        <div
          className={
            "flex flex-col py-[40px] mobile:py-[20px] items-center w-full"
          }
        >
          <img
            alt={""}
            onClick={goToProfileHandler}
            src={item?.avatarOwner ?? user_avatar}
            className={
              "rounded-[15px] object-cover cursor-pointer mb-[8px] w-[40px] h-[40px]"
            }
          />
          <div className={"cursor-pointer"} onClick={goToProfileHandler}>
            {item?.author}
          </div>
        </div>
        <div className={"flex flex-col items-center w-full"}>
          <div
            onClick={() => onPressPostHandler(item?.id, index)}
            className={"flex w-full items-center justify-center "}
          >
            <img className={"max-h-[350px]"} src={item?.filePath} />
          </div>
          <div className={"flex flex-col w-full px-[20px] py-[8px]"}>
            <div className={"flex justify-between items-center"}>
              <div className={"flex"}>
                <div className={"flex mr-[8px]"}>
                  <LikeIcon
                    className={"cursor-pointer"}
                    onClick={() => onPressLikeHandler(item?.id)}
                  />
                  <Typography text={item?.likesCount} variant={"h3"} />
                </div>
                <div className={"flex mr-[8px]"}>
                  <DisLikeIcon
                    onClick={() => onPressDisLikeHandler(item?.id)}
                    className={"mt-[4px] cursor-pointer"}
                  />
                  <Typography text={item?.dislikesCount} variant={"h3"} />
                </div>
                <div className={"flex mr-[8px]"}>
                  <CommentsIcon
                    onClick={() => onPressPostHandler(item?.id, index)}
                    className={"mt-[3px] cursor-pointer"}
                  />
                  <Typography text={item?.commentsCount} variant={"h3"} />
                </div>
              </div>
              <div className={"text-10 text-[#808080] font-normal"}>
                {transformDate(item?.createDateTime)}
              </div>
            </div>
          </div>
          <div className={"w-full flex gap-[20px] items-start px-[20px]"}>
            <div className={"text-10 text-black font-normal mb-[7px]"}>
              <div
                dangerouslySetInnerHTML={{
                  __html: item?.description?.replace(/\n/g, "<br />"),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
