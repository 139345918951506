import React, { useEffect } from "react";
import { BrowserRouter, useNavigate, useRoutes } from "react-router-dom";
import { Provider, useDispatch, useSelector } from "react-redux";
import { store } from "store/store";
import { getAppData } from "store/selectors";
import { authMeTC } from "store/thunks";
import { routes } from "./routes";
import { Loader, Modal } from "shared/ui";
import { appActions, postActions } from "store/actions";
import { IModal } from "interfaces";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "./errorFallback";
import { NewPostModal } from "../features/newPost";

export const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isInit, loader, openNewPostModal, modal, isAuth } =
    useSelector(getAppData);
  useEffect(() => {
    dispatch(authMeTC());
  }, []);

  const routing = useRoutes(routes(isAuth));

  const closeModal = () => {
    dispatch(appActions.setModalAC({} as IModal));
    if (modal.navigateTo) {
      navigate(modal.navigateTo, { replace: true });
    }
  };

  const closeNewPostModal = () => {
    dispatch(postActions.setOpenNewPostModalAC(false));
  };

  if (!isInit) {
    return <Loader loading={isInit} />;
  }

  return (
    <>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => console.log("error")}
      >
        <Modal
          title={modal?.title}
          open={!!modal?.title}
          onClose={closeModal}
          text={modal?.text}
        />
        <Loader loading={loader} />
        <NewPostModal open={openNewPostModal} onClose={closeNewPostModal} />
        {routing}
      </ErrorBoundary>
    </>
  );
};

export const RootApp = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  );
};
