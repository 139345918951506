import { RootStateType } from "store/store";

export const getDataForProfile = (state: RootStateType) => ({
  userData: state.profile.userData,
  postsData: state.profile.postsData,
  postPage: state.profile.postPage,
  postSize: state.profile.postSize,
  loader: state.app.loader,
});

export const getDataForSubscriptionsPage = (state: RootStateType) => ({
  followers: state.profile.followers,
  page: state.profile.page,
  size: state.profile.size,
  loader: state.app.loader,
});
export const getDataForFollowersPage = (state: RootStateType) => ({
  subscriptions: state.profile.subscriptions,
  userinfo: state.auth.user,
  page: state.profile.page,
  size: state.profile.size,
  loader: state.app.loader,
});
